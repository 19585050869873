const objectives = {
  objectives: {
    cycles: {
      title: "Ciclos de Objetivos",
      createNew: "Crear Nuevo Ciclo",
      viewDetails: "Ver Detalles",
      noResults: "No se encontraron ciclos de objetivos",
      search: "Buscar",
      backToList: "Volver a Ciclos",
    },
    status: {
      scheduled: "Programado",
      active: "Activo",
      finished: "Finalizado",
    },
    cycle: {
      name: "Nombre",
      status: "Estado",
      createdAt: "Creado el",
      period: "Período",
      assignations: "Asignaciones",
      completion: "Completado",
      actions: "Acciones",
      startDate: "Fecha de inicio",
      endDate: "Fecha de fin",
      namePlaceholder: "Ingrese nombre del ciclo",
      startDatePlaceholder: "Seleccione fecha de inicio",
      endDatePlaceholder: "Seleccione fecha de fin",
      startDateError: "La fecha de inicio no puede ser anterior a hoy",
      endDateError:
        "La fecha de fin no puede ser anterior a la fecha de inicio",
      uploadAssignations: "Subir Asignaciones",
      time: "Hora",
    },
    assignation: {
      user: "Usuario",
      email: "Correo",
      objective: "Objetivo",
      status: "Estado",
      viewDetails: "Ver Detalles",
      noAssignations: "No hay asignaciones aún",
      noAssignationsDescription:
        "Sube un archivo Excel con asignaciones para comenzar",
      uploadDescription:
        "Sube un archivo Excel con asignaciones para este ciclo.",
      formatTitle: "Formato de Excel Requerido",
      formatDescription: "El archivo Excel debe tener el siguiente formato:",
      formatExample:
        "Columna A: evaluador@ejemplo.com | Columna B: evaluado@ejemplo.com",
      formatNote:
        "Coloque los correos de evaluadores en la columna A y los correos de evaluados en la columna B de su hoja Excel.",
      changeFile: "Cambiar Archivo",
      dragAndDrop: "Arrastra y suelta el archivo aquí, o",
      browseFiles: "Explorar Archivos",
      excelOnly: "Solo archivos Excel (.xlsx, .xls)",
      uploadResults: "Resultados de la Carga",
      uploadResultsSummary:
        "Procesadas {total} asignaciones: {success} exitosas, {errors} errores",
      uploadResultsSuccess: "Éxito",
      uploadResultsErrors: "Errores",
      uploadResultsClose: "Cerrar",
      uploadResultsRetry: "Intentar de Nuevo",
      uploadResultsNoErrors:
        "¡Todas las asignaciones fueron procesadas exitosamente!",
      sender: "Asignador",
      receiver: "Colaborador",
      objectivesCount: "Objetivos",
      objectivesDetails: "Detalles de Objetivos",
      objectives: "Objetivos",
      noObjectives: "No se encontraron objetivos para esta asignación.",
      ponderation: "Ponderación",
      target: "Meta",
      tabs: {
        total: "Total",
        withObjectives: "Con Objetivos",
        withoutObjectives: "Sin Objetivos",
        details: "Detalles",
        history: "Historial de Cambios",
      },
      reminders: {
        sendReminder: "Enviar Recordatorio",
        confirmationTitle: "Enviar Recordatorio",
        confirmationMessage:
          "¿Estás seguro de que quieres enviar un recordatorio a todas las asignaciones sin objetivos?",
        cancel: "Cancelar",
        confirm: "Enviar",
        success: "Recordatorios enviados con éxito",
        error: "Error al enviar recordatorios",
      },
    },
    filters: {
      all: "Todos",
      scheduled: "Programados",
      active: "Activos",
      finished: "Finalizados",
    },
    edit: {
      edit: "Editar",
      save: "Guardar",
      cancel: "Cancelar",
      title: "Título",
      ponderation: "Ponderación (%)",
      type: "Tipo",
      targetValue: "Valor Objetivo",
      deadline: "Fecha Límite",
      ponderationError: "La ponderación total debe ser igual a 100%",
      ponderationWarning: "La ponderación total debe ser igual a 100%",
      saveSuccess: "Objetivos guardados exitosamente",
    },
    types: {
      number: "Número",
      money: "Dinero",
      boolean: "Sí/No",
      "1_to_5": "Escala (1-5)",
    },
    history: {
      title: "Historial de Cambios",
      noChanges: "No hay historial de cambios disponible.",
      fetchError: "Error al obtener el historial de cambios",
      objectiveChange: "Objetivo {number}: {field}",
      changedBy: "Modificado por",
      unknownUser: "Usuario Desconocido",
    },
  },
};

export default objectives;
