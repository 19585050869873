// AUTH ACTIONS
export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const PASSWORD_RESETED = "PASSWORD_RESETED";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const SET_USER_ROLES = "SET_USER_ROLES"
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";

// FEEDBACK ACTIONS
export const GET_FEEDBACKS = "GET_FEEDBACKS";
export const OPEN_FEEDBACK = "OPEN_FEEDBACK";
export const NEW_FEEDBACK = "NEW_FEEDBACK";
export const NEW_CONVERSATION = "NEW_CONVERSATION";

// SURVEYS ACTIONS
export const GET_USER_SURVEYS_SESSIONS = "GET_USER_SURVEYS_SESSIONS";
export const GET_USER_SURVEY = "GET_USER_SURVEY";

// ACCOUNT ACTIONS
export const SET_CURRENT_ACCOUNT = "SET_CURRENT_ACCOUNT";

// INFO ACTINS
export const OPEN_INFO = "OPEN_INFO";
export const CLOSE_INFO = "CLOSE_INFO";
export const OPEN_LOADER = "OPEN_LOADER";
export const CLOSE_LOADER = "CLOSE_LOADER";

// VIDEO ACTIONS
export const OPEN_VIDEO = "OPEN_VIDEO";
export const CLOSE_VIDEO = "CLOSE_VIDEO";

// RECOGNITION IMAGE ACTIONS
export const SET_CUSTOM_IMAGES = "SET_CUSTOM_IMAGES";

// AI WORKFLOWS ACTIONS
export const SET_WORKFLOWS_IDS = "SET_WORKFLOWS_IDS";
