const topbar = {
  topbar: {
    conditions: "Termos e Condições",
    language: "Idioma",
    logout: "Sair",
    polite: "Política de Privacidade",
    settings: "Configurações",
    userType: {
      admin: "Admin",
      support: "Suporte",
      employee: "Funcionário",
    },
    copyToClipboard: "Copiado para a área de transferência",
    demoMessage: {
      oneDay: "Você tem {{remainingDays}} dia de teste grátis restante",
      days: "Você tem {{remainingDays}} dias de teste grátis restantes",
      hours: "Você tem {{remainingHours}} horas de teste grátis restantes",
      finish: "Seu período de demonstração está quase no fim!",
    },
  },
};

export default topbar;
