const objectives = {
  objectives: {
    cycles: {
      title: "Objective Cycles",
      createNew: "Create New Cycle",
      viewDetails: "View Details",
      noResults: "No objective cycles found",
      backToList: "Back to Cycles",
    },
    status: {
      scheduled: "Scheduled",
      active: "Active",
      finished: "Finished",
    },
    cycle: {
      name: "Name",
      status: "Status",
      createdAt: "Created at",
      period: "Period",
      assignations: "Assignations",
      completion: "Completion",
      actions: "Actions",
      startDate: "Start Date",
      endDate: "End Date",
      namePlaceholder: "Enter cycle name",
      startDatePlaceholder: "Select start date",
      endDatePlaceholder: "Select end date",
      startDateError: "Start date cannot be before today",
      endDateError: "End date cannot be before start date",
      uploadAssignations: "Upload Assignations",
      time: "Time",
    },
    assignation: {
      user: "User",
      email: "Email",
      objective: "Objective",
      status: "Status",
      viewDetails: "View Details",
      noAssignations: "No assignations yet",
      noAssignationsDescription:
        "Upload an Excel file with assignations to get started",
      uploadDescription:
        "Upload an Excel file with assignations for this cycle.",
      formatTitle: "Required Excel Format",
      formatDescription: "The Excel file should have the following format:",
      formatExample:
        "Column A: evaluator@example.com | Column B: evaluated@example.com",
      formatNote:
        "Place evaluator emails in column A and evaluated emails in column B of your Excel sheet.",
      changeFile: "Change File",
      dragAndDrop: "Drag and drop file here, or",
      browseFiles: "Browse Files",
      excelOnly: "Excel files only (.xlsx, .xls)",
      uploadResults: "Upload Results",
      uploadResultsSummary:
        "Processed {total} assignations: {success} successful, {errors} errors",
      uploadResultsSuccess: "Success",
      uploadResultsErrors: "Errors",
      uploadResultsClose: "Close",
      uploadResultsRetry: "Try Again",
      uploadResultsNoErrors: "All assignations were processed successfully!",
      sender: "Assignor",
      receiver: "Collaborator",
      objectivesCount: "Objectives",
      objectivesDetails: "Objectives Details",
      objectives: "Objectives",
      noObjectives: "No objectives found for this assignation.",
      ponderation: "Ponderation",
      target: "Target",
      tabs: {
        total: "Total",
        withObjectives: "With Objectives",
        withoutObjectives: "Without Objectives",
        details: "Details",
        history: "Change History",
      },
      reminders: {
        sendReminder: "Send Reminder",
        confirmationTitle: "Send Reminder",
        confirmationMessage:
          "Are you sure you want to send a reminder to all assignations without objectives?",
        cancel: "Cancel",
        confirm: "Send",
        success: "Reminders sent successfully",
        error: "Error sending reminders",
      },
    },
    filters: {
      all: "All",
      scheduled: "Scheduled",
      active: "Active",
      finished: "Finished",
    },
    edit: {
      edit: "Edit",
      save: "Save",
      cancel: "Cancel",
      title: "Title",
      ponderation: "Ponderation (%)",
      type: "Type",
      targetValue: "Target Value",
      deadline: "Deadline",
      ponderationError: "Total ponderation must equal 100%",
      ponderationWarning: "Total ponderation must equal 100%",
      saveSuccess: "Objectives saved successfully",
    },
    types: {
      number: "Number",
      money: "Money",
      boolean: "Yes/No",
      "1_to_5": "Scale (1-5)",
    },
    history: {
      title: "Change History",
      noChanges: "No change history available.",
      fetchError: "Error fetching change history",
      objectiveChange: "Objective {number}: {field}",
      changedBy: "Changed by",
      unknownUser: "Unknown User",
    },
  },
};

export default objectives;
