const common = {
  common: {
    modal: {
      submit: "Confirmar",
    },
    yes: "Sim",
    no: "Não",
    none: "Nenhum",
    items: "itens",
  },
};

export default common;
