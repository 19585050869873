const newDashboard = {
  newDashboard: {
    title: "Reportería",
    header: {
      titles: {
        generals: "Resultados Generales",
        participation: "Tasa de Activación",
        bozEnps: "BOZ & eNPS",
        dimentions: "Resultados por Dimensión",
        metricsTime: "Metricas en el tiempo",
        comparisons: "Comparaciones",
      },
      randomMeetingModal: {
        title: "Random meeting",
        tooltipTitle:
          "Reunión coordinada:  Al menos una persona respondió el Ice breacker",
        meetingTableTitle: "¿Se coordino la reunión?",
        captainsTableTitle: "Capitanes",
        placeholderSearch: "Buscar Capitan...",
        captain: "Capitan",
        participant: "Participante",
        buttons: {
          subtitlebutton1: "Ronda",
          subtitlebutton2: "Estado",
        },
        buttonsContent1: {
          option: "Ronda",
        },
        buttonsContent2: {
          option1: "Todas",
          option2: "Coordinada",
          option3: "Pendiente",
        },
        loading: "Cargando...",
      },
    },
    generalResults: {
      title: "Resultados Generales",
      activeUsers: {
        title: "Usuarios activos",
        text: "que la semana pasada",
      },
      group: {
        title: "Equipos",
        text: "Última actualización:",
      },
      userFeedback: {
        title: "Feedback 1:1",
        text: "Recibidos el último mes",
      },
      newFeedbacks: {
        title: "Comentarios nuevos",
        text: "que la semana pasada",
      },
      activeSurveys: {
        title: "Encuestas activas",
        text: "que la semana pasada",
      },
      noPreviousPeriodData: "sin datos de la semana pasada",
      noCurrentPeriodData: "sin datos de esta semana",
      tooltip: "Ver más",
    },
    activationRate: {
      title: "Tasa de activación",
      circleChart: "Activación",
      tooltip:
        "La tasa de activación es la tasa entre usuarios activos / usuarios totales.",
      noHistoricData: "Sin datos históricos",
      noCurrentData: "Sin datos de activación para este ciclo",
      noData: "Sin datos históricos y de activación para este ciclo",
      seeMore: "Ver más",
    },
    bozRate: {
      title: "Indice BOZ",
      subtitle: "Promedio de todas las preguntas",
      tooltip:
        "El índice BOZ es el promedio de todas las preguntas que han sido respondidas para el ciclo y equipo/segmento seleccionado. Adicionalmente, puedes ver cuantas preguntas han sido enviadas del ciclo actual.",
      svg: {
        semiCircle: {
          critical: "Crítico",
          veryLow: "Muy bajo",
          low: "Bajo",
          good: "Bueno",
          veryGood: "Muy bueno",
          extraordinary: "Extraordinario",
          admirable: "Admirable",
        },
        arrowStatistics: {
          text: "respecto al periodo anterior",
          noPreviousData: "Sin datos del ciclo anterior",
          noCurrentData: "Sin datos de este ciclo",
        },
        line: {
          ask: "Preguntas enviadas",
          outOf: "de",
        },
        lineWithoutAsk: "Sin datos de preguntas",
      },
    },
    eNPS: {
      title: "eNPS",
      statistics: {
        promoters: "Promotores ",
        promoter: "Promotor",
        passives: "Pasivos ",
        passive: "Pasivo",
        detractors: "Detractores ",
        detractor: "Detractor",
        all: "Todos",
      },
      tooltip:
        "El employee Net Promoter Score (eNPS) refleja que tan probable es que tu equipo recomiende tu organización como un buen lugar para trabajar. El puntaje (desde -100 a 100) se calcula como (promotores - detractores) / número total de trabajadores * 100",
      svg: {
        tooltip: {
          detractors: "Detractores",
          passives: "Pasivos",
          promoters: "Promotores",
        },
        arrowStatistics: {
          text: "respecto al periodo anterior",
          noPreviousData: "Sin datos del ciclo anterior",
          noCurrentData: "aún no se mide el ENPS de este ciclo",
        },
        goodBenckmark: "Sobre el benchmark de empresas de Burnout-Zero",
        badBenckmark: "Bajo el benchmark de empresas de Burnout-Zero",
        iqualBenchmark: "Benchmark sobre la media de empresas de Burnout-zero",
        noBenchmarkData: "Aún no se mide el benchmark",
      },
      benchBoz: "Benchmark BOZ",
      commentsModal: {
        title: "Comentarios detractores",
        subtitle: "Anónimo",
      },
    },
    dimensionMetrics: {
      title: "Resultados por dimensión",
      tooltip:
        "Puedes ir buceando en la información haciendo click en la dimensión o en la misma barra de puntajes. Para volver un nivel más arriba, haz click en cualquier lugar del gráfico",
      noResults: "Sin información para este ciclo",
    },
    metricsTime: {
      title: "Metricas en el tiempo",
      tooltip:
        "El valor de una métrica se calcula como una media móvil a 90 días. Por ejemplo, el valor para la fecha actual, es un promedio de todas las respuestas de los últimos 90 días",
      noMetricsTime: "Sin información para este ciclo",
    },
    participation: {
      label: "Participación",
    },
    comparisons: {
      title: "Comparaciones",
      tooltip:
        "Puedes comparar los puntajes de los equipos o segmentos en un mapa de calor. Selecciona desde el filtro la información que quieres comparar",
      emptyStates: {
        teams: "Seleccione uno o varios equipos para ver la comparación",
        segments: "Seleccione uno o varios segmentos para ver la comparación",
      },
      heatmap: {
        lowest: "Puntaje más bajo",
        highest: "Puntaje más alto",
      },
      header: {
        title: {
          teams: "Equipos",
          segments: "Segmentos",
        },
        compare: "Compara",
        select: "Selecciona",
        filter: "Filtra",
      },
      checkboxSelect: {
        selected: "Seleccionados",
        noInfo: "No hay información.",
      },
      allSelect: {
        allSelect: "Selecionar todos",
      },
    },
    privacyMessage: {
      title:
        "Para mantener la privacidad y el anonimato de tus colaboradores, es necesario tener un mínimo de 4 usuarios activos.",
      subTitle:
        "Te faltan un total de {{number}} usuarios activos para poder utilizar el dashboard.",
    },
    filterCycle: {
      currentCycle: "Ciclo actual (#{{cycle}})",
      pastCycle: "Ciclo #{{cycle}}",
    },
    recognitions: {
      title: "Reconocimientos",
      subTitle: "Recibidos el último mes",
    },
  },
};

export default newDashboard;
