import axios from "axios";

const clientAxios = axios.create({
  baseURL: process.env.REACT_APP_REQUEST_BASE_URL,
  headers: {
    "X-Request-Source": "client",
  },
});

clientAxios.interceptors.response.use(
  response => response, // Return response as is
  error => {
    if (error.response && error.response.status === 401) {
      return Promise.resolve({ data: null });
    }
    return Promise.reject(error);
  }
);

export default clientAxios;
