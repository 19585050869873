const common = {
  common: {
    modal: {
      submit: "Confirm",
    },
    yes: "Yes",
    no: "No",
    none: "None",
    items: "items",
  },
};

export default common;
