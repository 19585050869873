const common = {
  common: {
    modal: {
      submit: "Confirmar",
    },
    yes: "Sí",
    no: "No",
    none: "Ninguno",
    items: "elementos",
  },
};

export default common;
