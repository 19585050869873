const surveys = {
  surveys: {
    title: "Tus encuestas",
    newSurvey: "Nueva encuesta",
    actives: "Activas",
    scheduled: "Agendadas",
    archived: "Archivadas",
    drafts: "Borradores",
    archiveTitle: "¿Quieres archivar esta encuesta?",
    totalUsersSent: "Enviado a",
    totalUsersResponse: "Respuesta",
    coworker: "colaborador",
    coworkers: "colaboradores",
    all: "todos",
    editFields: "Titulo y descripción",
    sendReminder:
      "Enviar un recordatorio a los colaboradores que no hayan respondido",
    anonymous: {
      true: "Esta encuesta es anónima, el administrador no podrá ver tus respuestas individuales",
      false:
        "Esta encuesta no es anónima, el administrador podrá ver tus respuestas individuales",
    },
    answer: {
      alert:
        "¡Esto es una previsualización de la encuesta que les llegara a los colaboradores, las siguientes respuestas no seran almacenadas!",
    },
    questions: {
      helper: {
        text: "Escribe tu respuesta aquí",
        multipleChoice: "Selecciona una opción",
        priorityOrder:
          "Arrastra las opciones en el orden de prioridad que prefieras",
        nps: 'Donde 0 es "No recomendaría en absoluto" y 10 es "Recomendaría absolutamente"',
      },
      npsTextLow: "¿Qué aspectos deben mejorar para aumentar tu puntuación?",
      npsTextHigh:
        "¿Qué podemos hacer para asegurarnos de que nos continúes recomendando como lugar de trabajo?",
    },
    team: {
      singular: "Equipo ",
      plural: "Equipos ",
      seeAll: "Ver equipos",
    },
    segment: {
      singular: "Segmento ",
      plural: "Segmentos ",
      seeAll: "Ver segmentos",
    },
    individual: {
      singular: "Colaborador",
      plural: "Colaboradores",
      seeAll: "Ver colaboradores",
    },
    warningMessage: {
      alertWarningMessage:
        "Debe de tener por lo menos cuatro contribuyentes en alguna categoria",
    },
    table: {
      title: "Título",
      status: "Estado",
      percentageResponse: "% de respuesta",
      createdAt: "Fecha de creación",
      scheduled: "Agendada para la fecha",
      visibility: "Visibilidad",
    },
    show: {
      surveys: "Encuestas",
      detailSurvey: "Detalle encuesta",
      responseRate: "Tasa de respuesta",
      status: "Estado",
      active: "Activa",
      archived: "Archivada",
      published: "Publicada",
      scheduled: "Agendada",
      content: "Contenido",
      question: "pregunta",
      questions: "preguntas",
      anonymous: "Anónimo",
      noAnonymous: "No anónimo",
      comments: "Ver comentarios",
      visibility: "Visibilidad",
    },
    create: {
      buttonMore: {
        seeDraft: "Ver borrador",
        seeTemplate: "Ver plantilla",
        seeSurvey: "Ver encuesta",
        saveDraft: "Guardar como borrador",
        deleteDraft: "Eliminar borrador",
        saveTemplate: "Guardar como plantilla",
        saveAsNewTemplate: "Guardar como nueva plantilla",
        updateTemplate: "Actualizar plantilla",
        delete: "Eliminar encuesta",
        deleteTemplate: "Eliminar plantilla",
      },
      firstStep: {
        dialogTitle: "Crear encuesta personalizada",
        dialogTitleTemplate: "Nueva plantilla",
        title: "Título",
        titlePlaceholder: "Escribe el nombre de la encuesta",
        titlePlaceholderTemplate: "Escribe el nombre de la plantilla",
        description: "Descripción",
        finalMessage: "Añadir mensaje al finalizar la encuesta",
        descriptionPlaceholder: "Escribe la descripción",
        required: "Requerido",
        lowUsers: "Necesitas mínimo 4 colaboradores para encuesta anónima",
      },
      secondStep: {
        questionSummary: "Pregunta {{number}}",
        questionTitle: "Título de la pregunta",
        questionTitlePlaceholder: "¿Cómo describes a tus compañeros?",
        title: "Tipo de pregunta",
        text: "Texto",
        nps: {
          title: "NPS",
          label:
            "Las preguntas de <strong>NPS</strong> se configuran de manera automática en <strong>escala de 0 a 10</strong>, donde 0 es <strong>No recomendaría en absoluto</strong> y 10 es <strong>Recomendaría absolutamente</strong>. Donde los usuarios que responden entre 9 - 10 serán considerados como <strong>promotores</strong>, 7 - 8 <strong>pasivos</strong> y 0 - 6 <strong>detractores</strong>.<br />Adicionalmente, tanto los pasivos como detractores deberán ingresar comentarios para completar su evaluación.",
        },
        opinionScale: {
          title: "Escala de opinión",
          minLabel: "Etiqueta 0",
          minLabelPlaceholder: "Ejemplo: muy en desacuerdo",
          maxLabel: "Etiqueta 10",
          maxLabelPlaceholder: "Ejemplo: totalmente de acuerdo",
        },
        multipleChoice: {
          title: "Selección múltiple",
          optionLabel: "Opción {{number}}",
          optionLabelPlaceholder: "Opción {{number}}",
        },
        priorityOrder: {
          title: "Orden de prioridad",
          optionLabel: "Opción {{number}}",
          optionLabelPlaceholder: "Opción {{number}}",
        },
        newQuestion: "Agregar otra pregunta",
        newOption: "Agregar otra opción",
      },
      thirdStep: {
        dialogTitle: "Audiencia y permisos",
        dialogSubtitle: "Los usuarios inactivos NO recibirán esta encuesta",
        title: "¿Quién realizará esta encuesta personalizada?",
        creator: "Creador",
        organization: "Organización",
        audience: "Audiencia",
        all: "Todos",
        team: "Equipo",
        segment: "Segmento",
        individual: "Selección individual",
        selectTeam: "Seleccionar equipo",
        selectSegment: "Seleccionar segmento",
        shippingOptions: "Opciones de envío",
        sendNow: "Enviar ahora",
        scheduleShipping: "Agendar",
        selectDate: "Fecha",
        selectTime: "Hora",
        previsualization: "Previsualización de envío",
        anonymous: "Encuesta anónima",
        notAnonymous: "Encuesta no anónima",
        deliveryOptions: "Opciones de envío",
      },
      editFields: "Nuevo titulo y descripción",
      newTemplateFields: "Titulo y descripción de la nueva plantilla",
      success: "¡Encuesta creada satisfactoriamente!",
      button: "Ver mis encuestas",
      saveSuccess: "Encuesta enviada con exito",
      scheduledSuccess: "Encuesta agendada con exito",
      saveSuccessTemplate: "¡Éxito! Plantilla guadada",
      saveError:
        "Hubo un error al crear la encuesta, por favor intente nuevamente",
      saveErrorTemlate:
        "Hubo un error al crear la planilla, por favor intente nuevamente",
      noQuestions:
        "Debes agregar al menos una pregunta para enviar esta encuesta",
    },
    user: {
      beginTitle: "Empezar",
      back: "Anterior",
      next: "Siguiente",
      complete: "Completado",
      incomplete: "Incompleto",
      end: "Finalizar",
      thanks: "¡Muchas gracias por tus respuestas!",
      close: "Cerrar esta ventana",
      deleteColabs: "¿Estás seguro de que deseas eliminar este colaborador?",
      deleteColabsTitle: "Eliminar Colaborador",
      addColabsTitle: "Colaborador que deseas agregar",
      addColabs: "Selecciona el colaborador que deseas agregar",
      textOption: {
        placeholder: "Escribe aquí tu opinión",
      },
      labels: "Donde 0 es {{ badLabel }} y 10 {{ goodLabel }}",
    },
    tooltips: {
      visibility: "Ver resultados",
      sendReminder: "Enviar recordatorio",
      archive: "Archivar encuesta",
      download: "Descargar reporte",
      cancelSending: "Anular envío",
      reschedule: "Reagendar encuesta",
      information:
        "El equipo o segmento requiere 4 usuarios activos (encuesta anónima) o 1 (no anónima) para incluirlo en la audiencia",
      npsInformation:
        "El switch no se puede desactivar si la encuesta contiene al menos una pregunta de tipo NPS",
      addColabs: "Agregar colaboradores",
    },
    report: {
      success: "Reporte descargado exitosamente",
      noInfo: "No hay respuestas aún para descargar",
    },
    reminder: {
      successMessage: "Recordatorio de encuesta enviado con exito",
      errorMessage:
        "Hubo un error al enviar el recordatorio de encuesta, por favor, intente de nuevo",
    },
    addColabs: {
      title: "Elige los colaboradores para agregar a la encuesta",
      required: "Selecciona al menos 1",
      successMessage: "Colaboradores agregados exitosamente",
      successDeleteMessage: "Colaborador eliminado exitosamente",
      errorMessage:
        "Hubo un error al agregar los colaboradores, por favor intente nuevamente",
      errorDeleteMessage:
        "Hubo un error al eliminar el colaborador, por favor intente nuevamente",
    },
    confirmation: {
      title: "La encuesta se enviará automaticamente",
      subtitle: "¿Deseas continuar?",
    },
    newSurveysButton: {
      emptyPage: "Encuesta en blanco",
      template: "Encuesta desde plantilla",
    },
    templateModal: {
      title: "¿Desea guardar esta encuesta como plantilla?",
      subtitle:
        "Esta encuesta se guardará como plantilla en la sección de plantillas para ser usada en futuras encuestas",
      buttonSave: "Guardar como plantilla",
      notSave: "No guardar",
    },
    newTemplate: {
      saveTemplate: "Guardar plantilla",
      deleteTemplate: "Eliminar plantilla",
      useTemplate: "Usar plantilla",
      edit: "Editar",
      titleDeleteModal: "¿Seguro que deseas eliminar esta plantilla?",
    },
    seeAllAnswers: "Ver todas las respuestas",
    answers: {
      emptyStateTitle: "No hay respuestas aún",
      emptyStateSubtitle: "Cuando las haya, se mostrarán aquí",
      emptyStateScheduleTitle: "Envío de encuesta agendada para el",
    },
    responseRate: {
      first: "Tasa de",
      second: "respuesta",
    },
    scheduledSurvey: {
      titleDeleteModal:
        "¿Desea eliminar la encuesta o guardarla como borrador?",
      titleRescheduleSurvey: "¿Desea reagendar la encuesta?",
      errorDeleteMessage:
        "Hubo un error al eliminar la encuesta agendada, por favor intenre nuevamente",
      deleteSuccess: "Encuesta eliminada exitosamente",
      saveAsDraft: "Guardar como borrador",
      deleteScheduledSurvey: "Eliminar",
      changeScheduleAtSuccess:
        "Fecha de envío de encuesta reagendada exitosamente",
    },
    template: {
      title: "Plantillas",
      newSurveysButton: "Crear nueva plantilla",
      used: "Usada {{date}}",
      created: "Creada {{date}}",
      updated: "Actualizada {{date}}",
      notUsed: "Sin usar aún",
      successMessage: "Exito! Plantilla guardada",
      noQuestions:
        "Debes agregar al menos una pregunta para guardar como plantilla",
      noQuestionsUse:
        "Debes agregar al menos una pregunta para usar esta plantilla",
      templateExist:
        "Ya existe una plantilla. Si desea guardar los cambios, por favor, introduzca un nuevo título para la plantilla.",
      errorMessage:
        "Hubo un error al crear la planilla, por favor intente nuevamente",
      errorDeleteMessage:
        "Hubo un error al eliminar la plantilla, por favor intenre nuevamente",
      emptyStateTitle: "No hay plantillas aún",
      emptyStateSubtitle:
        "Puedes crearlas desde el botón de Crear nueva plantilla",
      emptyStateButtonCreate: "O haciendo click aquí",
    },
    draftSurvey: {
      title: "Borrador",
      draftSurveyExist:
        "Borrador existente! Si desea guardar el borrador, coloque otro titulo",
      noQuestions:
        "Debes agregar al menos una pregunta para usar este borrador",
      errorMessage:
        "Hubo un error al crear el borrador, por favor intente nuevamente",
      successDeleteMessage: "¡Éxito! Borrador eliminado",
      errorDeleteMessage:
        "Hubo un error al eliminar el borrador, por favor intenre nuevamente",
      successMessage: "¡Éxito! Borrador guardado",
      useDraft: "Usar borrador",
      titleDeleteModal: "¿Seguro que deseas eliminar este borrador?",
      saveAsDraft: "La encuesta se ha guardado como borrador",
    },
    bozTemplate: {
      title: "Plantillas Boz",
      seeAllTemplates: "Ver todas las plantillas Boz",
    },
    multipleChoice: {
      lessThan2Options:
        "Las preguntas de selecección multiple necesita como mínimo 2 opciones",
    },
    priorityOrder: {
      lessThan4Options:
        "Las preguntas de orden de prioridad necesitan mínimo completar 4 opciones",
      buttonSeeMore: "Mostrar más...",
    },
    opinionScale: {
      lessThan2Values:
        "Las preguntas de escala de opinion necesitan las etiquetas completas",
    },
    imagesSurvey: {
      anonymousSurvey: "Encuesta anónima",
      publicSurvey: "Encuesta pública",
    },
    descriptionsPrefix: {
      anonymousSurvey: "⚠️ TUS RESPUESTAS SERÁN ANÓNIMAS ⚠️",
      publicSurvey: "⚠️ TUS RESPUESTAS SERÁN PÚBLICAS ⚠️",
      description: "Descripción",
      subtitle: "Encuesta personalizada",
    },
    onboarding: {
      title:
        "Antes de empezar con la encuesta, te pedímos completar estos datos",
      teams: {
        noSelected: "Aún no lo sé",
      },
    },
    daily_survey: {
      hello: "Hola",
      title: "¡Ha llegado tu encuesta diaria!",
      begin: "Empezar",
      previous: "Anterior",
      next: "Siguiente",
      end: "Finalizar",
      success_title: "Tu encuesta diaria ha sido guardada",
      privacy: "Recuerda tu respuesta se va a mantener 100% anónima",
      close: "Cerrar esta ventana",
      expired:
        "Esta encuesta ya venció. Recuerda que tienes 2 semanas para contestar.",
      completed: "Esta encuesta ya fue contestada.",
    },
    performance: {
      title: "Evaluación de Desempeño",
      new: "Nueva performance",
      export: "Exportar",
      performanceSections: {
        title: "Performance BOZ",
        evaluationsTitle: "Evaluaciones: Competencias y Objetivos",
        evaluationsDescription: "Crea evaluaciones de desempeño",
        objectivesTitle: "Objetivos",
        objectivesDescription: "Define los objetivos de los equipos en {{name}}",
        valuesTitle: "Valores / Principios",
        valuesDescription: "Define las dimensiones de evaluación de desempeño",
        scalesTitle: "Escalas de Evaluación",
        scalesDescription:
          "Define las escalas de evaluación de desempeño, con la cuál evaluarás a tus colaboradores",
        reportsTitle: "Reportes",
        reportsDescription:
          "Reportería para visualización y analítica de desempeño",
      },
      performanceSurveys: {
        new: "Nueva encuesta",
        title: "Encuestas de performance",
        seeAllTemplates: "Ver todas las encuestas",
        createSurvey: "Crear encuesta",
        surveyType: "Tipo de encuesta: {{ type }}",
        openSurvey: "Abrir encuesta",
        deleteSurvey: "Eliminar encuesta",
        titleCancelScheduledSurvey:
          "¿Esta segur@ de anular el envío de la encuesta?",
        cancelScheduledSurvey: "Anular",
        errorCancelMessage:
          "Hubo un error al anular la encuesta agendada, por favor intenre nuevamente",
        cancelSuccess: "Encuesta anulada exitosamente",
      },
      autoevaluation: "Autoevaluación",
      managerTeam: "Manager a equipo",
      teamManager: "Equipo a manager",
      surveyTypeText: "Elige el tipo de encuesta *",
      tabs: {
        all: "Todos",
        completed: "Completados",
        pendings: "Pendientes",
        updateAt: "Fecha de actualización",
      },
      name: "Nombre",
      evaluator: "Evaluador",
      status: "Estado",
      percentageResponse: "% de respuesta",
      totalSessions: "Total de sesiones",
      noPerformances: "Aún no hay performances",
      noArchivedPerformances: "Aún no hay performances archivadas",
      actives: "Activas",
      scheduled: "Agendadas",
      finalized: "Finalizadas",
      typeOfEmptyBody: {
        actives: "Aún no hay encuestas activas",
        scheduled: "Aún no hay encuestas agendadas",
        finalized: "Aún no hay encuestas finalizadas",
        all: "Aún no hay encuestas",
        completed: "Aún no hay encuestas completadas",
        pendings: "Aún no hay encuestas pendientes",
      },
      surveyType: "Tipo de encuesta",
      participationPercentage: "% de participación",
      session: "Sesiones",
      scheduleDate: "Fecha agendada",
      finalizationDate: "Fecha de finalización",
      deleteSurveySuccess: "Encuesta eliminada exitosamente",
      deleteSurveyError:
        "Hubo un error al intentar eliminar la encuesta, por favor intente nuevamente",
      category: {
        title: "Categoria",
        competence: "Competencia",
        objetive: "Objetivo",
      },
      dimension: {
        title: "Escribe la dimensión de las preguntas",
        placeholder: "Dimensión",
      },
      excel: {
        importObjetives: {
          title: "Importa aquí la asignación de objetivos",
          button: " Subir objetivos",
          titleSuccess: "Los objetivos se subieron correctamente",
        },
        importAssigments: {
          title: "Importa aquí la asignación de colaboradores",
          button: " Subir asignaciónes",
          titleSuccess: "Las asignaciónes se subieron correctamente",
        },
      },
      addSessionModal: {
        title: "Asignaciones Performance",
        evaluatorSelector: "Evaluador",
        evaluatedSelector: "Evaluado",
        required: "Requerido *",
      },
      sessions: {
        deleteAssignment: "Eliminar asignación",
        deleteSession: "Eliminar sesión",
        titleModal: "¿Estas seguro de que quieres eliminar esta asignación?",
        titleModalAllDelete:
          "¿Estas seguro de que quieres eliminar todas las asignaciones de este evaluador?",
        deleteSessions: {
          successMessage: "¡Sesión eliminada exitosamente!",
          errorMessage:
            "Hubo un error al intentar borrar la sesión, por favor intente nuevamente",
        },
        createSessions: {
          successMessage: "¡Sesión creada exitosamente!",
          errorMessage:
            "Hubo un error al intentar crear la sesión, por favor intente nuevamente",
        },
      },
      sessionDetail: {
        state: {
          completed: "Completada",
          inProgress: "En proceso",
        },
      },
    }
  }  
};

export default surveys;
