const objectives = {
  objectives: {
    cycles: {
      title: "Ciclos de Objetivos",
      createNew: "Criar Novo Ciclo",
      viewDetails: "Ver Detalhes",
      noResults: "Nenhum ciclo de objetivos encontrado",
      backToList: "Voltar para Ciclos",
    },
    status: {
      scheduled: "Agendado",
      active: "Ativo",
      finished: "Finalizado",
    },
    cycle: {
      name: "Nome",
      status: "Status",
      createdAt: "Criado em",
      period: "Período",
      assignations: "Atribuições",
      completion: "Conclusão",
      actions: "Ações",
      startDate: "Data de início",
      endDate: "Data de término",
      namePlaceholder: "Digite o nome do ciclo",
      startDatePlaceholder: "Selecione a data de início",
      endDatePlaceholder: "Selecione a data de término",
      startDateError: "A data de início não pode ser anterior a hoje",
      endDateError: "A data de término não pode ser anterior à data de início",
      uploadAssignations: "Carregar Atribuições",
      time: "Hora",
    },
    assignation: {
      user: "Usuário",
      email: "E-mail",
      objective: "Objetivo",
      status: "Status",
      viewDetails: "Ver Detalhes",
      noAssignations: "Nenhuma atribuição ainda",
      noAssignationsDescription:
        "Carregue um arquivo Excel com atribuições para começar",
      uploadDescription:
        "Carregue um arquivo Excel com atribuições para este ciclo.",
      formatTitle: "Formato de Excel Necessário",
      formatDescription: "O arquivo Excel deve ter o seguinte formato:",
      formatExample:
        "Coluna A: avaliador@exemplo.com | Coluna B: avaliado@exemplo.com",
      formatNote:
        "Coloque os e-mails dos avaliadores na coluna A e os e-mails dos avaliados na coluna B da sua planilha Excel.",
      changeFile: "Alterar Arquivo",
      dragAndDrop: "Arraste e solte o arquivo aqui, ou",
      browseFiles: "Procurar Arquivos",
      excelOnly: "Apenas arquivos Excel (.xlsx, .xls)",
      uploadResults: "Resultados do Upload",
      uploadResultsSummary:
        "Processadas {total} atribuições: {success} bem-sucedidas, {errors} erros",
      uploadResultsSuccess: "Sucesso",
      uploadResultsErrors: "Erros",
      uploadResultsClose: "Fechar",
      uploadResultsRetry: "Tentar Novamente",
      uploadResultsNoErrors:
        "Todas as atribuições foram processadas com sucesso!",
      sender: "Atribuidor",
      receiver: "Colaborador",
      objectivesCount: "Objetivos",
      objectivesDetails: "Detalhes dos Objetivos",
      objectives: "Objetivos",
      noObjectives: "Nenhum objetivo encontrado para esta atribuição.",
      ponderation: "Ponderação",
      target: "Meta",
      tabs: {
        total: "Total",
        withObjectives: "Com Objetivos",
        withoutObjectives: "Sem Objetivos",
        details: "Detalhes",
        history: "Histórico de Alterações",
      },
      reminders: {
        sendReminder: "Enviar Lembrete",
        confirmationTitle: "Enviar Lembrete",
        confirmationMessage:
          "Tem certeza de que deseja enviar um lembrete para todas as atribuições sem objetivos?",
        cancel: "Cancelar",
        confirm: "Enviar",
        success: "Lembretes enviados com sucesso",
        error: "Erro ao enviar lembretes",
      },
    },
    filters: {
      all: "Todos",
      scheduled: "Agendados",
      active: "Ativos",
      finished: "Finalizados",
    },
    edit: {
      edit: "Editar",
      save: "Salvar",
      cancel: "Cancelar",
      title: "Título",
      ponderation: "Ponderação (%)",
      type: "Tipo",
      targetValue: "Valor Alvo",
      deadline: "Prazo",
      ponderationError: "A ponderação total deve ser igual a 100%",
      ponderationWarning: "A ponderação total deve ser igual a 100%",
      saveSuccess: "Objetivos salvos com sucesso",
    },
    types: {
      number: "Número",
      money: "Dinheiro",
      boolean: "Sim/Não",
      "1_to_5": "Escala (1-5)",
    },
    history: {
      title: "Histórico de Alterações",
      noChanges: "Nenhum histórico de alterações disponível.",
      fetchError: "Erro ao buscar histórico de alterações",
      objectiveChange: "Objetivo {number}: {field}",
      changedBy: "Alterado por",
      unknownUser: "Usuário Desconhecido",
    },
  },
};

export default objectives;
