const topbar = {
  topbar: {
    conditions: "Términos y condiciones",
    language: "Lenguaje",
    logout: "Cerrar sesión",
    polite: "Política de privacidad",
    settings: "Configuración",
    userType: {
      admin: "Admin",
      support: "Soporte",
      employee: "Colaborador",
    },
    copyToClipboard: "Copiado al portapapeles",
    demoMessage: {
      oneDay: "Te queda {{remainingDays}} día de prueba gratis",
      days: "Te quedan {{remainingDays}} días de prueba gratis",
      hours: "Te quedan {{remainingHours}} horas de prueba gratis",
      finish: "¡Tu demo está por terminar!",
    },
  },
};

export default topbar;
