import { SET_CURRENT_ACCOUNT } from "../actions/types";

const initialState = {
  current: {},
  currentUser: {},
  featureFlags: {},
  roles: [],
  permissions: [],
};

export default function accountReducers(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_ACCOUNT:
      return {
        current: action.payload.account,
        currentUser: action.payload.user,
        featureFlags: action.payload.featureFlags,
        roles: action.payload.roles,
        permissions: action.payload.permissions,
      };
    default:
      return state;
  }
}
