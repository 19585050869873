const sidebar = {
  sidebar: {
    dashboard: "Dashboard",
    users: "Usuarios",
    teams: "Equipos",
    surveys: "Encuestas",
    feedbacks: "Feedbacks",
    questions: "Preguntas",
    settings: "Configuración",
    privacity: "Privacidad",
    recognitions: "Reconocimientos",
    performance: "Desempeño",
    timeOff: "Tiempo Libre",
    employeeProfile: "Mi Perfil",
  },
};

export default sidebar;
