const newDashboard = {
  newDashboard: {
    title: "Report",
    header: {
      titles: {
        generals: "General Results",
        participation: "Activation Rate",
        bozEnps: "BOZ & eNPS",
        dimentions: "Results by dimension",
        metricsTime: "Metrics in time",
        comparisons: "Comparisons",
      },
      randomMeetingModal: {
        title: "Random meeting",
        tooltipTitle:
          "Coordinated meeting: At least one person responded to the icebreaker",
        meetingTableTitle: "Was the meeting coordinated?",
        captainsTableTitle: "Captains",
        placeholderSearch: "Search Captain...",
        captain: "Captain",
        participant: "Participant",
        buttons: {
          subtitlebutton1: "Select batch",
          subtitlebutton2: "Select State",
        },
        buttonsContent1: {
          option: "Batch",
        },
        buttonsContent2: {
          option1: "All",
          option2: "Coordinated",
          option3: "Pending",
        },
        loading: "Loading...",
      },
    },
    generalResults: {
      title: "General Results",
      activeUsers: {
        title: "Active users",
        text: "than last week",
      },
      group: {
        title: "Groups",
        text: "Last update:",
      },
      userFeedback: {
        title: "Feedback 1:1",
        text: "Received in the last month",
      },
      newFeedbacks: {
        title: "New feedbacks",
        text: "than last week",
      },
      activeSurveys: {
        title: "Active surveys",
        text: "than last week",
      },
      noPreviousPeriodData: "no data from previous week",
      noCurrentPeriodData: "no data from this week",
      tooltip: "See more",
    },
    activationRate: {
      title: "Activation Rate",
      circleChart: "Activation",
      tooltip:
        "The activation rate is the ratio between active users / total users.",
      noHistoricData: "No historical data",
      noCurrentData: "No activation data for current cycle",
      noData: "No historical and activation data for current cycle",
      seeMore: "See more",
    },
    bozRate: {
      title: "BOZ Rate",
      subtitle: "Average of all questions",
      tooltip:
        "The BOZ rate is the average of all questions that have been answered for the selected cycle and team/segment. Additionally, you can see how many questions have been sent for the current cycle.",
      svg: {
        semiCircle: {
          critical: "Critical",
          veryLow: "Very low",
          low: "Low",
          good: "Good",
          veryGood: "Very good",
          extraordinary: "Extraordinary",
          admirable: "Admirable",
        },
        arrowStatistics: {
          text: "compared to the previous cycle",
          noPreviousData: "No previous cycle's data",
          noCurrentData: "The ENPS for this cycle has not been measured yet",
        },
        line: {
          ask: "Sent questions",
          outOf: "out of",
        },
        lineWithoutAsk: "Without ask data",
      },
    },
    eNPS: {
      title: "Employee Net Promoter Score (eNPS)",
      statistics: {
        promoters: "Promoters ",
        promoter: "Promoter",
        passives: "Passives ",
        passive: "Passive",
        detractors: "Detractors ",
        detractor: "Detractor",
        all: "All",
      },
      tooltip:
        "The employee Net Promoter Score (eNPS) reflects how likely your team is to recommend your organization as a good place to work. The score (from -100 to 100) is calculated as (promoters - detractors) / total number of workers * 100",
      svg: {
        tooltip: {
          detractors: "Detractors",
          passives: "Passives",
          promoters: "Promoters",
        },
        arrowStatistics: {
          text: "compared to the previous cycle",
          noPreviousData: "no previous cycle's data",
          noCurrentData: "no current cycle's data",
        },
        goodBenckmark: "Above the benchmark of Burnout-Zero companies",
        badBenckmark: "Below the benchmark of Burnout-Zero companies",
        iqualBenchmark: "Average to the benchmark of Burnout-Zero",
        noBenchmarkData: "The benchmark has not been measured yet",
      },
      commentsModal: {
        title: "Detractor comments",
        subtitle: "Anonymous",
      },
      benchBoz: "Benchmark BOZ",
    },
    dimensionMetrics: {
      title: "Results by dimension",
      tooltip:
        "You can dive into the information by clicking on the dimension or on the score bar itself. To go back one level up, click anywhere on the graph",
      noResults: "No information for this cycle",
    },
    metricsTime: {
      title: "Metrics evolution in time",
      tooltip:
        "The value of a metric is calculated as a 90-day moving average. For example, the value for today's date is an average of all responses from the last 90 days.",
      noMetricsTime: "No information for this cycle",
    },
    participation: {
      label: "Participation",
    },
    comparisons: {
      title: "Comparisons",
      tooltip:
        "You can compare the scores of teams or segments on a heat map. Select from the filter the information you want to compare",
      emptyStates: {
        teams: "Select one or more teams to see the comparisons",
        segments: "Select one or more segments to see the comparisons",
      },
      heatmap: {
        lowest: "Lowest score",
        highest: "Highest score",
      },
      header: {
        title: {
          teams: "Teams",
          segments: "Segments",
        },
        compare: "Compare",
        filter: "Filter",
        select: "Select",
      },
      checkboxSelect: {
        selected: "Selected",
        noInfo: "No information",
      },
      allSelect: {
        allSelect: "Select all",
      },
    },
    privacyMessage: {
      title:
        "To maintain the privacy and anonymity of your collaborators, it is necessary to have a minimum of 4 active users.",
      subTitle:
        "You are missing a total of {{number}} active users to be able to use the dashboard.",
    },
    filterCycle: {
      currentCycle: "Current cycle (#{{cycle}})",
      pastCycle: "Cycle #{{cycle}}",
    },
    recognitions: {
      title: "Recognitions",
      subTitle: "Received in the last month",
    },
  },
};

export default newDashboard;
